exports.components = {
  "component---src-components-templates-constituencies-page-template-js": () => import("./../../../src/components/Templates/ConstituenciesPageTemplate.js" /* webpackChunkName: "component---src-components-templates-constituencies-page-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-notice-to-california-residents-js": () => import("./../../../src/pages/notice-to-california-residents.js" /* webpackChunkName: "component---src-pages-notice-to-california-residents-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-software-demo-video-js": () => import("./../../../src/pages/software-demo-video.js" /* webpackChunkName: "component---src-pages-software-demo-video-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

